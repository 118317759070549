$mainFont: "Roboto", Arial, sans-serif;

// Size
$mainFontColor: #101010;
$mainFontSize: 16px;
$smallFontSize: 14px;
$mainFontWeight: 400;
$mainLineHeight: 1.4;

$lgDesktopWidth: 2560px;
$desktopWidth: 1280px;
$smDesktopWidth: 980px;
$tableWidth: 768px;
$phoneWidth: 480px;
$smPhoneWidth: 320px;

// Main color
$default-color: #fff;
$primary-color: #7fd5e0;
$primary-color-dark: #406b70;
$success-color: #26de81;
$danger-color: #fc5c65;
$warning-color: #fed330;
$light-color: #999;
$purple-color: #8854d0;
$dark-color: #101b1c;
$navbar-color: rgba(0, 0, 0, .5);
$dark-color-text: rgba(0, 0, 0, .8);
$dark-color2: #272424;

// Neutral Color
$neutral-primary: #303133;
$neutral-regular: #606266;
$neutral-secondary: #909399;
$neutral-placeholder: #c0c4cc;

//Theme color
$shades-primary: #7fd5e0;
$shades-regular: #6fbac4;
$shades-secondary: #5fa0a8;
$shades-placeholder: #4f858c;
$shades-low-dark: #406b70;
$shades-mid-dark: #305054;
$shades-mid-dark-second: #3e5255;
$shades-hight-dark: #203538;
$shades-over-dark: #101b1c;

$shades-gradient_1: rgba(0, 169, 196, 1);
$shades-gradient_2: rgba(57, 177, 197, 0.8715861344537815);

$shades-gradient_3: #00a9c4;
$shades-gradient_4: #0088d6;

// Border Color
$border-base: #dcdfe6;
$border-light: #e4e7ed;
$border-lighter: #ebeef5;
$border-lightex: #f2f6fc;

//Card Item Color

$color_1: black;
$color_2: #fff;
$background_color_1: #ededed99;
$background_color_2: transparent;
